body {
    margin: 0;
    padding: 0;
    background-color: var(--semi-color-fill-0);
    color: var(--semi-color-text-0);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}


::-webkit-scrollbar-corner {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: var(--semi-color-fill-1);
}

::-webkit-scrollbar-thumb:hover {
    border-radius: 6px;
    background: var(--semi-color-fill-0);
}


::-webkit-scrollbar-track {
    background: transparent;
}